import LINE from '@line/bot-sdk';
import { Friendship } from 'liff-type';
import { LineUser } from '../domains/LineUser';
import { useQuery } from 'react-query';
import { delay } from '../utils/delay';

const mockResponse = {
  display_name: 'foobar',
  'line-user-id': 'U11eedee1202e5051762b3814c0f4cae8',
  'line-access-token':
    'eyJhbGciOiJIUzI1NiJ9.xfz8QMDBJetHzhk0Nnx8bLzi6fK_irEDC6DcIfuy3UFOwzKyXB5oqRaOSaPdZuFENPZptfncMR18z-yq7UAykEgETFJIFMjnnVQK1H_7_0OHXY2ADKGlG3kjZ2qJ8VfbtVzlIsE6Hjeo3PF4I06TUl1WR20eoCPQ26LPDtG33l0.HmJE-lMK2I9gsgRlwD09E1q6eLhhqCDx9Ak7JcrBRdc',
  friend_flag: true,
};

export function useGetLineUser(liffId: string) {
  return useQuery<LineUser | undefined>(['liff'], async () => {
    if (process.env.REACT_APP_DEBUG_MODE) {
      await delay(1000);
      return mockResponse;
    }
    // NOTE:- LINE認証情報を取得
    await liff.init({ liffId });
    if (liff.isLoggedIn()) {
      // NOTE:- LINE認証済みの場合
      const [profile, friendship]: [LINE.Profile, Friendship] = await Promise.all([
        liff.getProfile(),
        liff.getFriendship().catch(() => ({ friendFlag: false })),
      ]);
      return {
        display_name: profile.displayName,
        'line-access-token': liff.getAccessToken(),
        'line-user-id': profile.userId,
        friend_flag: friendship.friendFlag,
      };
    } else {
      // NOTE:- LINE未認証の場合
      liff.login({
        redirectUri: window.location.href,
      });
      // NOTE:- LINEログインページに10秒経過後もリダイレクトされない場合はエラーをスロー
      await delay(10 * 1000);
      throw new LineLoginError();
    }
  });
}

export class LineLoginError extends Error {}
