import React from 'react';
import { toast as hotToast, Toaster as HotToaster } from 'react-hot-toast';
import { Toast } from 'react-hot-toast/dist/core/types';
import { delay } from '../utils/delay';
import { CollectionItem } from '../domains/CollectionItem';

interface IToastProps {
  message?: React.ReactNode;
  opts?: Partial<
    Pick<Toast, 'id' | 'icon' | 'duration' | 'ariaProps' | 'className' | 'style' | 'position' | 'iconTheme'>
  >;
}

export function toast({ message, opts }: IToastProps) {
  return hotToast((t) => <ToastItem message={message} id={t.id} />, opts);
}

interface IGachaProps {
  item: CollectionItem;
  onItemClick: (item: CollectionItem) => void;
}

export function gacha({ item, onItemClick }: IGachaProps) {
  const toastId = 'gacha';
  return hotToast.promise(
    delay(1500),
    {
      loading: (
        <div className="pointer-events-auto flex w-full max-w-md rounded-full bg-yellow-500 bg-gradient-to-r shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="flex-1 pl-3 pr-3 pt-2 pb-2">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg
                  className="h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              </div>
              <div className="ml-2 flex-1">
                <p className="text-sm font-semibold text-white">アイテムを取得しています...</p>
              </div>
            </div>
          </div>
        </div>
      ),
      success: (
        <div className="pointer-events-auto flex w-full max-w-md rounded-lg bg-gradient-to-r from-miscollepurple to-miscollepink shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="flex-1 p-2.5">
            <div className="flex items-center">
              <div className="flex-shrink-0 pt-0.5">
                <img className="h-10 w-10 rounded-lg" src={item.thumbnail_url!} alt={item.name} />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-semibold text-white">写真アイテムをGETしました!</p>
              </div>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={() => {
                onItemClick(item);
                hotToast.dismiss(toastId);
              }}
              className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent pl-2.5 pr-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-500"
            >
              <span className="rounded-md bg-indigo-800 pt-1 pb-1 pl-2 pr-2 text-xs text-white">開く</span>
            </button>
          </div>
        </div>
      ),
      error: null,
    },
    {
      id: toastId,
      icon: null,
      position: 'top-center',
    },
  );
}

export function Toaster() {
  return (
    <HotToaster
      toastOptions={{
        duration: Infinity,
        style: {
          padding: 0,
          boxShadow: 'none',
          background: 'none',
          borderRadius: 0,
        },
      }}
    />
  );
}

export function ToastItem({ message, id }: IToastProps & { id: string }) {
  return (
    <div
      onClick={() => hotToast.dismiss(id)}
      className="pointer-events-auto w-full min-w-full max-w-sm overflow-hidden rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <div className="py-2 px-4">
        <div className="flex items-start">
          <div className="flex-1">{message && <p className="text-sm font-semibold text-white">{message}</p>}</div>
        </div>
      </div>
    </div>
  );
}
