import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Loader } from './components/Loader';
import { useGetLineUser } from './hooks/useGetLineUser';
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from 'react-query';
import { useURLSearchParams } from './hooks/useURLSearchParams';
import { Toaster } from './components/Toast';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const VotePage = lazy(() => import('./pages/vote'));
const FavoritePage = lazy(() => import('./pages/favorite'));
const MailPage = lazy(() => import('./pages/mail'));
const NotFoundPage = lazy(() => import('./pages/404'));
const ErrorPage = lazy(() => import('./pages/error'));

function App() {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <HelmetProvider>
      <div className="min-h-screen bg-white">
        <Suspense fallback={<Loader className="min-h-screen text-emerald-500" />}>
          <ErrorBoundary onReset={reset} fallbackRender={(props) => <ErrorPage {...props} />}>
            <Suspense fallback={<Loader className="min-h-screen text-emerald-500" />}>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <Routing />
                </Router>
              </QueryClientProvider>
            </Suspense>
          </ErrorBoundary>
        </Suspense>
        <Toaster />
      </div>
    </HelmetProvider>
  );
}

function Routing() {
  const location = useLocation();
  const params = useURLSearchParams();
  const redirectTo = params.get('liff.state');
  const liffId = redirectTo ? new URLSearchParams(redirectTo.split('?')[1]).get('liffId') : params.get('liffId');
  const { data: user } = useGetLineUser(liffId ?? process.env.REACT_APP_LIFF_ID!);
  console.log(`Welcome to ${user?.display_name} !`);
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <Switch>
      <Route
        path="/vote/:contest_key/:gender_type/:entry_id/mail/auth"
        children={<MailPage liffId={liffId ?? process.env.REACT_APP_LIFF_ID!} />}
      />
      <Route
        path="/vote/:contest_key/:gender_type/:entry_id"
        children={<VotePage liffId={liffId ?? process.env.REACT_APP_LIFF_ID!} />}
      />
      <Route path="/favorite" children={<FavoritePage liffId={liffId ?? process.env.REACT_APP_LIFF_ID!} />} />
      <Route path="/404" children={<NotFoundPage />} />
      <Redirect to={{ ...location, pathname: `/404` }} />
    </Switch>
  );
}

export default App;
